import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import AgentModeFunctionH3 from './agentModeFunctionH3'

const Wrapper = styled.section`
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  margin: 24px auto 0;
  font-weight: bold;

  .h3-1 {
    margin: 0;
    padding: 12px 0;
    position: relative;
    z-index: 1;
    color: #fff;
    background: linear-gradient(
      to right,
      #18b5d1,
      #0332b3 20%,
      #0332b3 80%,
      #18b5d1
    );

    .p-h3-line1,
    .p-h3-line2 {
      span {
        background: #ffff00;
      }

      &:before,
      &:after {
        background: #ffff00;
      }
    }

    h3 {
      font-size: 32px;
    }

    :after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -55px;
      left: 0;
      width: 0px;
      height: 0px;
      margin: auto;
      border-style: solid;
      border-color: #0332b3 transparent transparent transparent;
      border-width: 55px 75px 0 75px;
    }
  }

  .img-agent-function {
    position: absolute;
    top: -75px;
    z-index: 2;

    img {
      height: 250px;
    }
  }

  .function-area01 {
    display: flex;
    justify-content: space-between;
    position: relative;

    :after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -75px;
      left: 0;
      width: 0px;
      height: 0px;
      margin: auto;
      border-style: solid;
      border-color: #0332b3 transparent transparent transparent;
      border-width: 55px 75px 0 75px;
    }
  }

  .p-section-inner {
    max-width: 1080px;
    padding: 130px 0 0;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }

  .agent-function-box {
    height: 610px;
    width: 520px;
    background-color: #f4f4f4;
    filter: drop-shadow(0px 3px 6px #ababab);
    border-radius: 10px;
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;

    .text-area01 {
      margin: none;
      background-color: #002faf;
      color: #fff;
      font-size: 26px;
      height: 150px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      height: 200px;
      margin: 20px 0;
    }

    .text-area02 {
      width: 380px;
      margin-bottom: 60px;

      p {
        font-size: 22px;
      }
    }
  }

  .function-area02 {
    margin-top: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .function-text {
      margin-bottom: 10px;

      h3 {
        margin: 0 0 10px;
      }

      span {
        font-size: 36px;
        background: linear-gradient(transparent 60%, rgb(255, 255, 102) 60%);
        padding: 0 5px;
      }
    }

    .h3-2 {
      margin: 0;
    }

    .h3-2 h3 {
      font-size: 24px;
    }

    .p-h3-line1,
    .p-h3-line2 {
      span {
        background: #002faf;
      }

      &:before,
      &:after {
        background: #002faf;
      }
    }
  }

  .function-img {
    max-width: 900px;
    width: 90vw;
    position: relative;
    margin: 0 0 10px;
    overflow: hidden;
    padding: 10px 10px 0;

    img {
      position: relative;
      top: 10px;
      width: calc(100% - 20px);
      filter: drop-shadow(0px 3px 6px #ababab);
    }
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.large} {
    .p-section-inner {
      padding: 60px 0 0;

      .img-agent-function {
        left: 60px;
        top: -40px;

        img {
          height: 170px;
          width: auto;
        }
      }

      .function-area01 {
        display: block;
      }

      .agent-function-box {
        height: auto;
        margin: 0 auto 30px;

        .text-area01,
        .text-area02 {
          font-size: 22px;
          height: unset;
          padding: 10px 0;
          margin: 0 0 10px;
        }

        img {
          height: 150px;
          margin: 10px 0;
        }
      }

      .function-img {
      }
    }
  }
`

/**
 * 代理申請機能紹介ページ-代理機能申請でできること
 * @constructor
 */
const AgentModeFunction02 = () => (
  <Wrapper>
    <AgentModeFunctionH3
      label="代理申請機能でできること"
      className="anim-fade-up h3-1"
    />
    <div className="p-section-inner">
      <div className="img-agent-function anim-fade-up">
        <img
          src="/images/official/function/agentMode02.png"
          alt="代理申請でできること"
        />
      </div>
      <div className="function-area01">
        <div className="agent-function-box anim-fade-up">
          <div className="text-area01">
            <p>
              本人に修正依頼しても
              <br />
              正しい情報で提出されない
            </p>
          </div>
          <img
            src="/images/official/function/agentMode03.png"
            alt="従業員が修正箇所を把握できていない"
          />
          <div className="text-area02">
            <p>
              従業員本人が修正箇所を把握しておらず、何度もチャットやメールで修正指示をしなければならない
            </p>
          </div>
        </div>
        <div className="agent-function-box anim-fade-up">
          <div className="text-area01">
            <p>
              提出された勤務表に誤りがある
              <br />
              状態で本人が休職してしまった
            </p>
          </div>
          <img
            src="/images/official/function/agentMode04.png"
            alt="提出された勤務表に誤りがある"
          />
          <div className="text-area02">
            <p>
              休職中の本人と業務連絡が取れず
              <br />
              勤務表の修正ができないため
              <br />
              締め処理が滞っている
            </p>
          </div>
        </div>
      </div>
      <div className="function-area02 anim-fade-up">
        <div className="function-text">
          <h3>
            <span>代理申請で一発解決!</span>
          </h3>
          <AgentModeFunctionH3
            label="代理申請に切り替えて本人の代わりに修正が可能"
            className="fade-in-auto h3-2"
          />
        </div>
        <div className="function-img">
          <img
            src="/images/official/function/agentMode05.png"
            alt="代理申請画面のスクリーンショット"
          />
        </div>
      </div>
    </div>
  </Wrapper>
)

export default AgentModeFunction02
